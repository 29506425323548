




import Vue from 'vue';
import StaticErrorTemplate from '@/components/templates/staticError/StaticErrorTemplate.vue';

export default Vue.extend({
    name: 'StaticError',
    components: {
        StaticErrorTemplate,
    },
});
